<template>
  <div class="login">
    <div class="loginCenter">
      <div class="header">
        <div ref="header1" @click="handlePageChange(1)">账号登录</div>
        <div ref="header2" @click="handlePageChange(2)">账号注册</div>
        <div ref="header3" @click="handlePageChange(3)">邮箱登录</div>
      </div>
      <div class="content">
        <div ref="content_pages" class="content_pages">
          <div ref="page1" class="page1">
            <el-form
              :model="form1"
              status-icon
              ref="form1"
              label-width="auto"
              :rules="form1.rules"
            >
              <el-form-item error="" prop="username">
                <template #label>
                  <span>账号</span>
                </template>
                <el-input type="text" v-model.trim="form1.username" />
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input type="password" v-model="form1.password"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleLoginByPassword()"
                  >登录</el-button
                >
                <el-button @click="$refs.form1.resetFields()">重置</el-button>
              </el-form-item>
            </el-form>
            <div class="page1_bottom">
              <div style="text-decoration: underline">
                👉🏼tips：初次访问请用手机号注册账号，😎体验管理员权限
              </div>
              <div>
                👉🏼tips：忘记账号&忘记密码，可以尝试寻求<router-link
                  style="color: #ff5b5b"
                  to="/help"
                  >帮助</router-link
                >
              </div>
            </div>
          </div>
          <div ref="page2" class="page2">
            <el-form
              :model="form2"
              status-icon
              ref="form2"
              label-width="auto"
              :rules="form2.rules"
            >
              <el-form-item prop="username">
                <span slot="label">用户名</span>
                <el-input v-model.trim="form2.username" type="text"></el-input>
              </el-form-item>

              <el-form-item prop="password">
                <span slot="label">密码</span>
                <el-input v-model="form2.password" type="password"></el-input>
              </el-form-item>

              <el-form-item prop="telephone">
                <template #label>
                  <span>手机号</span>
                </template>
                <el-input
                  class="clearNumberButton"
                  v-model="form2.telephone"
                  type="number"
                >
                  <template #suffix>
                    <el-button
                      style="padding-right: 10px"
                      type="text"
                      size="mini"
                      @click="handleGetSMSCode()"
                      :disabled="form2.smsDisabled"
                      :icon="form2.smsIcon"
                    >
                      {{ form2.smsText }}
                    </el-button>
                  </template>
                </el-input>
              </el-form-item>

              <el-form-item prop="smsCode">
                <template #label>
                  <span>验证码</span>
                </template>
                <el-input
                  class="clearNumberButton"
                  v-model="form2.smsCode"
                  type="number"
                >
                </el-input>
              </el-form-item>

              <el-form-item>
                <el-button @click="handleRegistry()" type="primary"
                  >注册</el-button
                >
                <el-button @click="$refs.form2.resetFields()">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div ref="page3" class="page3">开发中……敬请期待</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryUsername } from "../api/common/admin";

// todo: 将选项卡封装成组件
import { getSMS, loginByPassword, registryBySMS } from "../api/index";
export default {
  name: "login",
  data() {
    return {
      currentPage: 2,
      form1: {
        username: "",
        password: "",
        // usernameCache: {},
        rules: {
          username: [
            {
              required: true,
              validator: async (rule, value, callback) => {
                if (!value) {
                  callback(new Error("用户名必填"));
                } else if (/^[a-zA-Z\d]+$/.test(value)) {
                  callback();
                } else {
                  callback(new Error("用户名仅由字母和数字组合"));
                }
              },
              trigger: "blur",
            },
          ],
          password: [
            {
              required: true,
              trigger: "blur",
              message: "密码必填且长度在6~10之间",
              min: 6,
              max: 10,
            },
          ],
        },
      },
      form2: {
        username: "",
        password: "",
        telephone: "",
        smsCode: "",
        usernameCache: {},
        smsText: "发送验证码",
        smsDisabled: false,
        smsIcon: "",
        rules: {
          username: [
            {
              required: true,
              validator: async (rule, value, callback) => {
                if (!value) {
                  callback(new Error("用户名必填"));
                } else if (/^[a-zA-Z\d]+$/.test(value)) {
                  /**
                   * 以下内容为注册时，判断用户存不存在
                   */
                  //缓存之前的请求结果，避免同一个参数多次请求
                  if (value in this.form2.usernameCache) {
                    this.form2.usernameCache[value]
                      ? callback(new Error(`用户名${value}已存在`))
                      : callback();
                    return;
                  }
                  const data = await queryUsername(value);
                  this.form2.usernameCache[value] = data;
                  const exist = String(data) === "true";
                  if (exist) {
                    callback(new Error(`用户名${value}已存在`));
                  } else {
                    callback();
                  }
                } else {
                  callback(new Error("用户名仅由字母和数字组合"));
                }
              },
              trigger: "blur",
            },
          ],
          password: [
            {
              required: true,
              min: 6,
              max: 10,
              message: "密码必填且长度在6~10之间",
              trigger: "blur",
            },
          ],
          telephone: [
            {
              required: true,
              min: 11,
              max: 11,
              message: "手机号应为11位的数字",
            },
          ],
          smsCode: [
            {
              required: true,
              min: 6,
              max: 6,
              trigger: "blur",
            },
          ],
        },
      },
    };
  },
  methods: {
    handleRegistry() {
      let result;
      this.$refs.form2.validate((valid) => {
        result = valid;
      });
      if (result) {
        registryBySMS(
          {
            username: this.form2.username,
            password: this.form2.password,
            smsCode: this.form2.smsCode,
          },
          this.form2.telephone
        ).then((data) => {
          if (data) {
            this.$message({
              type: "success",
              message: "注册成功!!!",
            });
            this.handlePageChange(1);
          }
        });
      }
    },
    handleGetSMSCode() {
      if (/^1[3-9]\d{9}$/.test(this.form2.telephone)) {
        this.form2.smsText = "后端处理中";
        this.form2.smsIcon = "el-icon-loading";
        this.form2.smsDisabled = true;

        getSMS(this.form2.telephone).then((data) => {
          this.form2.smsIcon = ''
          if (data) {
            this.$message({
              type: "success",
              message: "验证码已发送，请注意查收",
            });
            //定义30秒的时间
            let duringTime = 30;
            const code = setInterval(() => {
              duringTime--;
              this.form2.smsText = `${duringTime}秒后重试`;
              
            }, 1e3);

            setTimeout(() => {
              clearInterval(code);
              this.form2.smsText = "发送验证码";
              this.form2.smsDisabled = false;
            }, duringTime * 1e3);
          }else {
            this.form2.smsDisabled = false
          }
        }).catch(data => {
          this.form2.smsDisabled = false
        });
      } else {
        this.$refs.form2.fields[2].validateMessage =
          "手机号应以1开头的11位数字";
        this.$refs.form2.fields[2].validateState = "error";
      }
    },
    handleLoginByPassword() {
      let result;
      this.$refs.form1.validate((valid) => {
        result = valid;
      });
      if (result) {
        loginByPassword({
          username: this.form1.username,
          password: this.form1.password,
        }).then((adminLoginVO) => {
          const { accessToken } = adminLoginVO;
          localStorage.setItem("accessToken", accessToken);
          this.$router.push({ name: "main" });
        });
      }
    },
    handlePageChange(index) {
      this.$refs.header1.classList.remove("currentPageStyle");
      this.$refs.header2.classList.remove("currentPageStyle");
      this.$refs.header3.classList.remove("currentPageStyle");
      if (index === 1) {
        this.$refs.content_pages.style.transform = "translateX(0)";
        this.$refs.header1.classList.add("currentPageStyle");
      } else if (index === 2) {
        this.$refs.content_pages.style.transform = "translateX(-100%)";
        this.$refs.header2.classList.add("currentPageStyle");
      } else {
        this.$refs.content_pages.style.transform = "translateX(-200%)";
        this.$refs.header3.classList.add("currentPageStyle");
      }
    },
  },

  mounted() {
    window.route = this.$route;
    // getSMS('15116732599').then(res => {
    //   if (res.data.code === 200) {
    //   }
    // })
    // registryBySMS(
    //   {
    //     username: "",
    //     password: "",
    //     smsCode: "",
    //   },
    //   "15116732599"
    // );
    this.currentPage = Number(this.$route.query.page) || 1;
    // alert(this.currentPage)
    this.handlePageChange(this.currentPage);
    // registryBySMS(
    //   {
    //     username: "lxy",
    //     password: "666",
    //     smsCode: "434727",
    //   },
    //   "15116732599"
    // ).then(console.log).catch(console.log)
    // getSMS('15116732599').then(res => {
    //   if (res.data.code === 200) {
    //     console.log(res.data);
    //   }
    // })
  },
};
</script>

<style scoped lang="less">
.currentPageStyle {
  background-color: #fff;
  color: black !important;
}

.login {
  background-color: #ecefff;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  // background-color: red;
  .loginCenter {
    background-color: white;
    overflow: hidden;
    border-radius: 20px;
    // height: 300px;
    width: 400px;
    // background-color: blueviolet;
    display: flex;
    flex-direction: column;

    .header {
      height: 45px;

      background-color: #f5f5f6;
      display: flex;
      text-align: center;
      & > div {
        height: 100%;
        flex-grow: 1;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(37, 38, 43, 0.36);
      }
    }
    .content {
      overflow: hidden;
      flex-grow: 0;

      color: white;

      .content_pages {
        color: black;
        display: flex;
        transform: translateX(-100%);
        transition: all 0.5s;
        & > div {
          width: 100%;
          height: 100%;
          flex-shrink: 0;
        }
        .page1 {
          flex-shrink: 0;
          box-sizing: border-box;
          padding-top: 30px;
          .el-input {
            width: 200px !important;
          }

          display: flex;
          align-items: center;
          flex-direction: column;

          .page1_bottom {
            padding: 10px 25px;
            font-size: 15px;
            line-height: 20px;
            div {
              margin: 5px 0;
            }
          }
        }

        .page2 {
          .clearNumberButton::v-deep input::-webkit-outer-spin-button,
          .clearNumberButton::v-deep input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
          }
          .el-input {
            width: 220px !important;
          }
          .clearNumberButton ::v-deep input[type="number"] {
            -moz-appearance: textfield;
          }

          justify-content: center;
          display: flex;

          padding-top: 30px;
          box-sizing: border-box;
        }

        .page3 {
        }
      }
    }
  }
}
</style>

